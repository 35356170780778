<template>
  <div class="manage-cookies__wrapper">
    <img
      v-focusable
      :src="$helper.getLinkToFlBucketFile('manage-cookies.png')"
      alt="Manage cookies"
      loading="lazy"
      @click="$_oneTrust_toggleCookiePreferencesPopup"
    />
  </div>
</template>

<script>
import mixins from '@/utils/mixins'

export default {
  name: 'FooterManageCookies',
  mixins: [mixins.oneTrust]
}
</script>

<style lang="scss" scoped>
.manage-cookies__wrapper {
  cursor: pointer;

  img {
    width: 120px;
    height: 30px;
  }
}
</style>
