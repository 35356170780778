<template>
  <div class="footer-follow-us-section__wrapper">
    <p class="footer-follow-us-section__title font-sans bold">Follow Us</p>
    <footer-subscribe-daily-news
      class="footer-follow-us-section__subscribe-daily-news"
    />
    <footer-socials
      :items="footerSocials"
      class="footer-follow-us-section__socials"
    />
    <p class="footer-follow-us-section__read font-sans">
      Read our <a-link :to="linkToTerms">Terms</a-link>,
      <a-link :to="linkToCookies">Cookies</a-link> and
      <a-link :to="linkToPrivacy">Privacy Notice</a-link>
    </p>
    <footer-manage-cookies class="footer-follow-us-section__manage-cookies" />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import FooterSubscribeDailyNews from './FooterSubscribeDailyNews'
import FooterSocials from './FooterSocials'
import { ROUTE_NAME } from 'enums/routes'
import FooterManageCookies from '@/components/AFooter/FooterFollowUsSection/FooterManageCookies'

export default {
  name: 'FooterFollowUsSection',
  components: { FooterManageCookies, FooterSubscribeDailyNews, FooterSocials },
  props: {
    footerSocials: propValidator([PROP_TYPES.ARRAY])
  },
  data() {
    return {
      linkToTerms: {
        name: ROUTE_NAME.TERMS_OF_USE
      },
      linkToPrivacy: {
        name: ROUTE_NAME.PRIVACY
      },
      linkToCookies: {
        name: ROUTE_NAME.COOKIES
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-follow-us-section__wrapper {
  position: relative;

  @include mobile {
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .footer-follow-us-section__title {
    @include mobile {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .footer-follow-us-section__subscribe-daily-news {
    width: 100%;
    margin-top: 15px;
  }

  .footer-follow-us-section__socials {
    margin-top: 20px;

    @include mobile {
      width: 100%;
      margin-top: 20px;
    }
  }

  .footer-follow-us-section__read {
    font-size: 12px;
    margin-top: 30px;

    @include mobile {
      margin-top: 20px;
    }
  }

  .footer-follow-us-section__manage-cookies {
    position: absolute;
    left: 0;
    margin-top: 14px;

    @include mobile {
      position: static;
    }
  }

  a {
    color: $c--white;

    &:hover {
      color: $c--main;
    }
  }
}
</style>
