<template>
  <a-icon
    :icon="ICON.FL"
    width="auto"
    height="auto"
    original
    class="footer-logo"
  />
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'FooterLogo',
  components: { AIcon },
  data() {
    return { ICON }
  }
}
</script>

<style lang="scss" scoped>
.footer-logo.a-icon {
  width: 200px;
  height: 40px;

  @include desktop-md {
    width: 150px;
    height: 30px;
  }

  @include mobile {
    width: 100px;
    height: 20px;
  }
}
</style>
