<template>
  <footer
    class="footer__wrapper"
    v-observe-visibility="footerVisibilityOptions"
  >
    <div class="footer__content-wrapper">
      <footer-logo class="footer__logo" />
      <div class="footer__main-content-wrapper">
        <div class="footer__main-content">
          <a-visibility hide :on="[$breakpoint.mobile]">
            <footer-menu-list :items="footerLeftSection" />
          </a-visibility>
          <a-visibility hide :on="[$breakpoint.mobile]">
            <i class="vertical-divider first" />
          </a-visibility>
          <a-visibility hide :on="[$breakpoint.mobile]">
            <footer-menu-list :items="footerRightSection" />
          </a-visibility>
          <a-visibility hide :on="[$breakpoint.mobile]">
            <i class="vertical-divider second" />
          </a-visibility>
          <footer-follow-us-section :footer-socials="stayConnected" />
        </div>
        <a-visibility show :on="[$breakpoint.mobile]">
          <i class="horizontal-divider" />
        </a-visibility>
        <footer-bottom-text class="footer__bottom-text" />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import FooterLogo from './FooterLogo'
import FooterFollowUsSection from './FooterFollowUsSection'
import FooterBottomText from './FooterBottomText'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { OBSERVER_CALLBACK_DEFAULT_THROTTLE } from 'enums/mutation-observer'
import * as types from '@/store/mutation-types'

export default {
  name: 'Footer',
  mixins: [serverCacheKey],
  components: {
    FooterLogo,
    FooterMenuList: hydrateWhenVisible(() => import('./FooterMenuList'), {
      props: ['items']
    }),
    FooterFollowUsSection,
    FooterBottomText
  },
  data() {
    return {
      footerVisibilityOptions: {
        callback: this.setFooterVisibilityState,
        throttle: OBSERVER_CALLBACK_DEFAULT_THROTTLE
      }
    }
  },
  computed: {
    ...mapGetters({
      footerLeftSection: 'footerLeftSection',
      footerRightSection: 'footerRightSection',
      stayConnected: 'stayConnected'
    })
  },
  methods: {
    ...mapMutations({
      setFooterVisibilityState: `element-visibility/${types.SET_FOOTER_VISIBILITY_STATE}`
    })
  }
}
</script>

<style lang="scss" scoped>
$default-gap-width: 62px;
$small-gap-width: 40px;
$logo-width: 200px;

.footer__wrapper {
  width: 100%;
  background: $c--footer;
  color: $c--white;
  margin-top: 20px;
  padding: 60px 0 27px;

  @include desktop-md {
    padding: 40px 15px 30px;
  }

  @include mobile {
    padding: 30px 15px 20px;
  }

  .footer__content-wrapper {
    display: flex;
    width: calc(1188px + #{$logo-width} + #{$default-gap-width});
    margin: 0 auto;

    @include desktop-lg {
      width: calc(1158px + #{$logo-width} + #{$default-gap-width});
    }

    @include desktop-md {
      width: 100%;
      flex-direction: column;
    }
  }

  .footer__logo {
    margin-right: $default-gap-width;

    @include desktop-md {
      margin: 0 0 40px 0;
    }

    @include tablet {
      margin-right: $small-gap-width;
    }

    @include mobile {
      margin: 0 auto 30px;
    }
  }

  .footer__main-content-wrapper {
    width: 1188px;

    @include desktop-lg {
      width: 1158px;
    }

    @include desktop-md {
      width: 100%;
    }
  }

  .footer__main-content {
    display: flex;
    align-items: stretch;
  }

  .vertical-divider {
    position: relative;
    margin: 0 $default-gap-width;

    &.second {
      margin-left: 98px;

      @include tablet {
        margin: 0 $small-gap-width;
      }
    }

    &::before {
      position: absolute;
      display: block;
      width: 1px;
      height: 100%;
      background: $c--white;
      content: '';
    }

    @include tablet {
      margin: 0 $small-gap-width;
    }
  }

  .horizontal-divider {
    display: block;
    width: 100%;
    height: 2px;
    margin-top: 20px;
    background: $c--white;
    content: '';

    @include mobile {
      margin-top: 12px;
    }
  }

  .footer__bottom-text {
    margin-top: 54px;

    @include mobile {
      margin-top: 10px;
    }
  }
}
</style>
